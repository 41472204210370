footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	line-height: 60px;
	border-top: 1px solid var(--color-yellow-middle);
}

@media screen and (max-width: 420px) {
	footer {
		font-size: small;
	}
}